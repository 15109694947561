a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

div.headerNavbar {
  height: 60px;
  background-color: #fff;
  position:relative;
  z-index: 10;
  box-shadow: 3px 3px 4px rgba(0,0,0,0.1);
}

div.headerNavbar.mobile {
  height: 80px;
  background-color: #121f22;
  position:relative;
  z-index: 10;
  box-shadow: 3px 3px 4px rgba(0,0,0,0.1);
}

div.headerMobileContainer {
  height: 60px;
  background-color: #fff;
  position: fixed;
  z-index: 100;
  top:0;
  left: 0;;
}

div.navHeader-list{
  top:80px;
  left:0;
  background-color: #121f22;

}

div.notifications{
  height: 18px;
  width: 18px;
  background-color: #AC0000;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  font-size: 0.8em;
  padding:0;
  top:-6px;
  right:0;
  cursor: pointer;
}

div.notifications span{
  margin-top: 3px;
  text-align: center;
}

button.btnNotifications{
  padding: 0 5px;
}

div.notificationMenu{
  background-color: white;
  border: 1px solid #e5e5e5;
  display: none;
  top:40px;
  right:0;
  border-radius: 7px;
  box-shadow: 2px 2px 3px rgba(0,0,0,0.1);
  padding: 20px;
}

div.notificationMenu.active{
  display: unset;
}

.trapezoidHeader {
  border-bottom: 60px solid #3971C5;
  border-left: 50px solid transparent;
  position: absolute;
  right: 0;
  top: 0;
}

.logStatusIcon {
  height: 15px;
  width: 15px;
  border-radius: 10px;
}

.logStatusIcon.online {
  background-color: darkgreen;
}
.logStatusIcon.offline {
  background-color: red;
}

.logStatusText {
  font-weight: normal;
}

.footerNavMobile-container {
  height: 80px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

a.navIcon-container {
  text-decoration: none !important;
  color: #000 !important;
  font-weight: bolder;
  font-size: 0.8em;
}
a.navIcon-container.horiz {
  text-decoration: none !important;
  color: #000 !important;
  font-weight: bolder;
  font-size: 1em;
}


a.navIcon-container.disabled {
  pointer-events: none !important;
  cursor: none;
  filter:invert(70%)

}

a.navIcon-container:not(.disabled) div img,
a.navIcon-container:not(.disabled) div label{
  cursor: pointer;
}

a.navIcon-container:not(.active) div img,
a.navIcon-container:not(.active) div label{
  filter:invert(30%)
}

a.navIcon-container:not(.disabled):hover {
  filter: brightness(0) !important;
}


.navbarSidebar-list{
  background-color: #121f22;
}

div.navbarSidebar{
  width:120px;
  z-index: 100;
  box-shadow: 0 4px 4px rgba(0,0,0,0.2);
  transition: 0.5s;
  position:relative;
}

div.navbarSidebar.active{
  width:auto;
}
div.navbarSidebar-brand{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.navbarHeader-brand{
  width: 100px;
   height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.navbarSidebar-brand img{
  width:100px
}
div.navbarHeader-brand img{
  width:150px;
  margin-right: 80px;
}

div.navbarSidebar:not(.active) div.navbarSidebar-list div.navbarSidebar-item {
  display: flex;
  justify-content: center;
}

div.navbarSidebar:not(.active) div.navbarSidebar-list div div.navbarSidebar-item a label,
div.navbarSidebar:not(.active) div.navbarSidebar-list div div.navbarSidebar-item button label
{
  display: none;
}

div.navbarSidebar-item{
  padding:20px 25px
}

label.navLabel{
  padding-left: 15px;
  color:white;
  font-size: 1.2em;
  margin-bottom: 0;
  cursor: pointer;
}

div.navbarSidebar-item a{
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

img.reverse{
  transform: rotate(180deg);
}
 

/* Per i sotto-menu più piccoli */
.subMenu label.navLabel {
  font-size: 1em; /* Riduci la dimensione del font */
  padding-left: 10px; /* Riduci il padding a sinistra */
}

.subMenu {
  margin-left: 20px;
  transition: all 0.3s ease;
}

.subMenu-entering,
.subMenu-exiting,
.subMenu-exited {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.subMenu-entered {
  max-height: 300px;
  transition: all 1s ease;
}