div.Azienda-item{
    border:1px solid #413f40;
    border-radius: 8px;
    padding: 15px 0;
    margin-bottom: 1rem;

    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
}

div.Azienda-item.hover{
    border:1px solid rgb(0, 125, 228);
    background-color: rgba(100,100,100,0.01);
}
div.clickable:hover{
    cursor: pointer;
}

/* label.status{
    font-size: 0.9em;
} */

label.status:not(.Rejected){
    color:rgb(0, 125, 228)
}

label.status.Rejected{
    color:rgb(228, 34, 0) !important
}
