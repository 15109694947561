.ordine-di-carico-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
  .table-container {
    margin-top: 20px;
    overflow-x: auto;
  }
  
  table {
    margin-top: 10px;
    border-collapse: collapse;
    width: auto; /* Imposta una larghezza automatica basata sul contenuto */
  }
  
  th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .header {
    text-align: center;
  }
  
  .footer {
    text-align: center;
    margin-top: 20px;
  }
  