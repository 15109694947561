table.vma-table{
  border-radius: 8px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

th.gridHead {
  /* border: 1px solid #555; */
  background-color: #e5e5e5;
  padding-bottom: 0 !important;
  /* padding-top:0 !important; */
}

th.gridHead div.headerTable {
  min-width: 50px;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

div.labelTh {
  min-height: 60px;
  color: #666;
  text-transform: capitalize;
}

tr.dati td.dati {
  border-left: 1px solid #bbb !important;
  border-bottom: 1px solid #bbb !important;
}
tr.dati td {
  border-bottom: 1px solid #bbb !important;
}

button.btn.btnColonne{
  color:#464646;
  font-size: 1.2em;
  background-color: transparent;
  border: none;
  outline: none;
}

button.btn.btnColonne:focus{
  border: none;
  outline: none;
  box-shadow: none;
}

tr.intestazione th {
  border-bottom: 1px solid #bbb !important;
  border-right: 1px solid #bbb !important;
}

tr.dati.selected {
  background-color: #e1f5ff !important;
}


div.filterSpace {
  min-height: 40px;
  border-top: 1px solid #bbb !important;
  background-color: #fff;
}

button.filterSearch,
div.filterSearch {
  border-radius: 7px;
  background-color: #f5f5f5;
  color: #555;
  padding: 5px;
  height: 40px;
  font-family: "regular";
  cursor: pointer;
  margin: 4px 0;
  border: none !important;
  outline: none !important;
  display: flex;
  align-items: center;
}

input.filterSearch {
  background-color: transparent !important;
  border: none !important;
  color: #333;
  cursor: pointer;
  width: 100%;
}

input.filterSearch:focus {
  border: none !important;
  outline: none !important;
}

table.table {
  border: 1px solid #bbb;
}

div.filterMenu {
  min-width: 300px;
  min-height: 200px;
  max-height: 400px;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  overflow-y: auto;
  align-content: flex-start;
  z-index: 200;
}

table.minHeight {
  min-height: 600px;
}

div.cell {
  min-height: 45px;
  display: flex;
}

div.cell.middle {
  align-items: center;
  vertical-align: middle;
  min-height: 55px;
}

td {
  padding: 5px 7px !important;
}

div#output div:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

div#output div:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

div.triangleBubbleTop {
  width: 26px;
  height: 10px;
  border-bottom: 10px solid #555;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  position: absolute;
  top: -10px;
  left: 20px;
}

div.bubbleMenu {
  /* min-height: 213px; */
  min-width: 220px;
  background-color: #555;
  max-width: 500px;
  position: absolute;
  top: 45px;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
}

button.pagination {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #e7f1f6;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.paginationAction {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.paginationAction:disabled {
  opacity: 0.3;
}

button.pagination.active {
  background-color: #008bdb;
  color: #fff;
  font-family: "bold";
}

button.paginationAction > img {
  height: 20px;
}

fieldset.gotoPage {
  border: 1px solid #333 !important;
  border-radius: 5px;
  padding: 5px;
}

legend.gotoPage {
  font-size: 0.9em;
  margin-bottom: 0;
  padding: 0 5px;
  width: auto;
}

input.gotoPage {
  padding-left: 4px;
  border: none;
  height: 20px;
  background-color: transparent;
  border-left: 1px solid #000;
  min-width: 100px;
}

input.gotoPage:focus {
  border: none;
  outline: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

button.gotoPage {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

tr.log:hover {
  cursor: pointer;
  background-color: #caedff !important;
}

div.logDetails-container {
  height: 100vh;
  width: 500px;
  background-color: white;
  position: fixed;
  top: 0;
  right: -500px;
  z-index: 500;
  transition: 0.5s;
}

div.backdrop-details{
  background-color: rgba(0,0,0,0.05);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 499;
  top: 0;
  display: none;
}

div.backdrop-details.active{
  display: block;
}


div.logDetails-container.active {
  right:0;
}

.deletedRow {
  text-decoration: line-through !important;
}

div.phasesContainer{
  max-height: 700px;
  overflow-y: auto;
}

/* div.custom-width {
  min-width: 100px;
} */

tr.table-light td{
  background-color: #f5f5f5;
}

span.info{
  font-size: 0.8em;
}button.btnEdit {
	height: 38px;
	width: 38px;
	padding: 0;
	border-radius: 6px;
}

button.btnEdit.view {
	height: 40px;
	width: 40px;
	padding: 0;
	border-radius: 6px;
	/* border:1px solid #888; */
	display: flex;
	justify-content: center;
	align-items: center;
}

img.btnEdit {
	height: 18px;
}
